<template>
  <ClientOnly>
    <VDialog
      v-bind="$attrs"
      close-on-back
      :retain-focus="false"
      @after-enter="checkOpen"
    >
      <slot />
    </VDialog>
  </ClientOnly>
</template>

<script setup lang="ts">
import { VDialog } from 'vuetify/components/VDialog'

const $router = useRouter()
const $route = useRoute()

const checkOpen = async () => {
  if (!$router?.options?.history?.state?.back) {
    await $router.push({ path: $route.path, query: $route.query, hash: '#modalOpen' })
    $router.push({ path: $route.path, query: $route.query })
  }
}
</script>

<style scoped></style>
